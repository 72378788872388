
    export default {
     props : ['blok'],
     data() {
         return {
            bestSellers : null,
            alreadyOnRequest : false,
         }
     },
     created() {
        if(process.client) this.getBestSellers();
     },
     mounted() {
         this.getBestSellers();
     },
     methods: {
         getBestSellers(){
             if(!this.bestSellers && !this.alreadyOnRequest){
                this.alreadyOnRequest = true;
                this.$axios.post("/products/search/new",{
                    search : "",
                    filters : {
                        specialOffer : true
                    },
                    pagination: {
                        page_size: 8
                    }
                }).then(axRes => {
                    this.bestSellers = axRes.data.products;
                    this.alreadyOnRequest = false;
                    if(process.client && this.bestSellers.length < 1){
                        this.$parent.$parent.forceHide = true;
                    }
                }).catch(axErr => {
                    console.log(axErr.stack);
                    this.alreadyOnRequest = false;
                })
                 
             }
         }
     },
    }
